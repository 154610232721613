import jQuery from 'jquery';
import popper from 'popper.js';
import bootstrap from 'bootstrap';


document.getElementsByClassName('header__toggler')[0].addEventListener('click', function() {
    var toggler = document.getElementsByClassName('header__navigation-mobile')[0];
    if (toggler.style.transform === 'translateY(-100%)') {
      toggler.style.transform = 'translateY(0%)'
    } else {
      toggler.style.transform = 'translateY(-100%)'
    }
}, false);